import { Auth } from 'aws-amplify'
import { CognitoUserInterface } from '@aws-amplify/ui-components'
import { Store } from './Store'
import { emailRegex } from '@/helpers'

interface AuthState extends Record<string, any> {
  isAuthenticated: boolean
  user: CognitoUserInterface | null
  email: string
}

class AuthStore extends Store<AuthState> {
  protected data(): AuthState {
    return {
      isAuthenticated: false,
      user: null,
      email: '',
    }
  }

  private _setUser(user: CognitoUserInterface | null) {
    this.state.user = user
    this.state.isAuthenticated = !!user
  }

  setEmail(email: string) {
    const _email = email.trim().toLowerCase()
    if (emailRegex.test(_email)) {
      this.state.email = _email
    }
  }

  async load() {
    try {
      const user = await Auth.currentAuthenticatedUser()
      this._setUser(user)
    } catch (err) {
      this.state.user = null
    }
  }

  async login(email: string, password: string) {
    const _email = email.trim().toLowerCase()
    const _password = password.trim()
    const user = await Auth.signIn(_email, _password)
    console.log('authSore login', user)
    this._setUser(user)
  }

  async logout() {
    await Auth.signOut()
    this._setUser(null)
  }

  async completeNewPassword(newPassword: string) {
    if (!this.state.user) {
      throw new Error(`No user`)
    }

    try {
      const user = await Auth.completeNewPassword(this.state.user, newPassword)
      // at this time the user is logged in if no MFA required
      this._setUser(user)
    } catch (err) {
      console.error(err)
      // rethrow
      throw err
    }
    return
  }

  async passwordResetSubmit(newPassword: string, verificationCode: string) {
    await Auth.forgotPasswordSubmit(this.state.email, verificationCode, newPassword)
    return
  }

  async resendConfirmationCode() {
    await Auth.forgotPassword(this.state.email)
  }

  async getAuthTokens() {
    return `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`
  }
}

export const authStore: AuthStore = new AuthStore()
