import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import '@/assets/styles/tailwind.css'

import Amplify from 'aws-amplify'
import aws_exports from '@/aws-exports'
import { applyPolyfills, defineCustomElements } from '@aws-amplify/ui-components/loader'
import { authStore } from '@/store/authStore'

Amplify.configure(aws_exports)
applyPolyfills()
  .then(() => defineCustomElements(window))
  .then(() => authStore.load())

createApp(App).use(router).mount('#app')
