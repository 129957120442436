import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router'
import Home from '@/views/Login.vue'
import { RouteName } from '@/router/RouteName'
import { authStore } from '@/store/authStore'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: RouteName.login,
    component: Home,
  },
  {
    path: '/',
    name: RouteName.authRoot,
    component: () => import(/* webpackChunkName: "authRoot" */ '@/views/AuthRoot.vue'),
    children: [
      {
        path: '/dashboard',
        name: RouteName.dashboard,
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      },
      // CODES
      {
        path: '/codes',
        name: RouteName.codesRoot,
        component: () => import(/* webpackChunkName: "codesRoot" */ '@/views/codes/CodesRoot.vue'),
        children: [
          {
            path: '/codes',
            name: RouteName.codesRead,
            component: () => import(/* webpackChunkName: "codesRead" */ '@/views/codes/CodesRead.vue'),
          },
          {
            path: '/codes',
            name: RouteName.codesCreate,
            component: () => import(/* webpackChunkName: "codesCreate" */ '@/views/codes/CodesCreate.vue'),
          },
        ],
      },
      // SUBSCRIPTIONS
      {
        path: '/subscriptions',
        name: RouteName.subscriptionRoot,
        component: () =>
          import(/* webpackChunkName: "subscriptionRoot" */ '@/views/subscriptions/SubscriptionRoot.vue'),
        children: [
          {
            path: '/subscriptions',
            name: RouteName.subscriptionRead,
            component: () =>
              import(/* webpackChunkName: "subscriptionRead" */ '@/views/subscriptions/SubscriptionRead.vue'),
          },
          {
            path: '/subscriptions',
            name: RouteName.subscriptionCreate,
            component: () =>
              import(
                /* webpackChunkName: "subscriptionCreate" */ '@/views/subscriptions/SubscriptionCreate.vue'
              ),
          },
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

// Guards
router.beforeEach((to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  // This site is all private.
  if (to.name === RouteName.login && !authStore.getState().isAuthenticated) {
    // this page is public, and the user is not authenticated
    next()
  } else if (to.name === RouteName.login && authStore.getState().isAuthenticated) {
    // person is authenticated - no need to go to login
    next({ name: RouteName.dashboard })
  } else if (!authStore.getState().isAuthenticated) {
    // not authenticated - send them to login
    next({ name: RouteName.login })
  } else {
    next()
  }
})

export default router
