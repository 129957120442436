export const emailRegex = /[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,10}/
/**
 * passwordRegex
 * Min length of 8 characters, requires one letter, and one number.
 * May contain special characters @$!%*#?&
 */
export const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*#?&]{8,}$/
export const urlRegex =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/
export const phoneRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
/**
 * @param seconds
 * Returns a Promise<void> after ***X seconds*** - default to 3 seconds
 */
export function asyncDelay(seconds = 3): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, seconds * 1000)
  })
}

export function isEmpty(obj: any) {
  if (obj == null) {
    return true
  }

  if (Array.isArray(obj)) {
    return !obj.length
  }

  if (typeof obj == 'string') {
    return !obj.length
  }

  const type = {}.toString.call(obj)

  if (type == '[object Object]') {
    return !Object.keys(obj).length
  }

  if (type == '[object Map]' || type == '[object Set]') {
    return !obj.size
  }

  // other primitive || unidentifed object type
  return Object(obj) !== obj || !Object.keys(obj).length
}

export const copyToClipboard = async (data: string) => {
  await navigator.clipboard.writeText(data)
}
